// Dependências 
import { storeToRefs } from "pinia"
import { useSettingsStore } from "~/stores/settings"

// Middleware
export default defineNuxtRouteMiddleware((to, from) => {
  // Pinia
  const settingsStore = useSettingsStore()
  const { accountSettings } = storeToRefs(settingsStore)

  // Variáveis
  const accountAuth = accountSettings.value.token
  const path = to.path.replace(/[/]*/g, '')

  if (accountAuth && path === 'minha-conta') {
    return navigateTo('/minha-conta/area-logada')
  }

  if (!accountAuth && path !== 'minha-conta') {
    return navigateTo('/minha-conta')
  }
})